<template>
  <div>
    <h2 class="text-left py-2 text-xl mb-2 font-bold">{{ zoneName === 'default' ? 'Salón' : zoneName }}</h2>
    <div class="grid grid-cols-3 gap-2 justify-center items-center">
      <div
        v-for="(zoneHour, hourKey) in auxHours"
        :key="hourKey">
        <button
          v-if="fromUncover"
          class="text-center w-full text-primary py-2 rounded-md transition-all border cursor-pointer disabled:text-gray-400 disabled:bg-gray-200"
          :class="{
            'bg-blue-200 border-blue-300': hour === zoneHour && zoneName === selectedZone,
            'bg-gray-400 text-red-400 h-9' : zoneHour === ''
          }"
          @click="setHour(zoneHour, zoneName)">
          {{ zoneHour }}
<!--          <label :for="`zoneValue-${index}-${hourKey}`">{{ zoneHour }}</label>-->
<!--          <input :id="`zoneValue-${index}-${hourKey}`" type="radio" v-model="hour" :value="zoneHour" readonly/>-->
        </button>
        <button
          v-else
          :disabled="!providerZone.includes(zoneHour)"
          class="text-center w-full text-primary py-2 rounded-md transition-all border cursor-pointer disabled:text-gray-400 disabled:bg-gray-200"
          :class="{
            'bg-blue-200 border-blue-300': hour === zoneHour && zoneName === selectedZone,
            'bg-gray-400 text-red-400 h-9' : zoneHour === ''
          }"
          @click="setHour(zoneHour, zoneName)">
          {{ zoneHour }}
          <!--          <label :for="`zoneValue-${index}-${hourKey}`">{{ zoneHour }}</label>-->
          <!--          <input :id="`zoneValue-${index}-${hourKey}`" type="radio" v-model="hour" :value="zoneHour" readonly/>-->
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'zoneCard',
  props: {
    zoneName: { required: false, type: String },
    index: { required: false, type: Number },
    providerZone: { required: false, type: Object },
    selectedZone: { required: false },
    selectedHour: { required: false },
    hoursList: { required: false, type: Array },
    loading: { required: false, type: Boolean },
    fromUncover: { required: false, type: Boolean, default: false }
  },
  emits: ['set-hour', 'modal'],
  data () {
    return {
      hour: this.providerZone[0],
      auxHours: []
    }
  },
  methods: {
    filteredHours () {
      //
      if (!this.providerZone.includes(this.selectedHour)) {
        // this.hour = this.providerZone[0]
        // this.setHour(this.hour, this.selectedZone)
        let $0 = 0
        while ($0 < this.providerZone.length) {
          if ((this.hour < this.providerZone[$0]) || ($0 === this.providerZone.length - 1)) {
            this.hour = this.providerZone[$0]
            // this.setHour(this.hour, this.selectedZone)
            if (this.hour !== this.selectedHour || this.zoneName !== this.selectedZone) {
              this.$emit('set-hour', { zoneHour: this.hour, zoneName: this.selectedZone })
            }
            break
          }
          $0++
        }
      }

      let principal = this.hoursList.indexOf(this.hour)

      let backwardOffset = principal - 4
      let forwardOffset = principal + 5

      const $1 = [...this.hoursList]
      if (backwardOffset <= 0) {
        for (let $2 = 0; $2 < Math.abs(backwardOffset); $2++) {
          $1.unshift('')
        }
        principal = $1.indexOf(this.selectedHour)
        backwardOffset = principal - 4
        forwardOffset = principal + 5
      }
      if (($1.length - forwardOffset) <= 0) {
        for (let $2 = 0; $2 < Math.abs(forwardOffset); $2++) {
          $1.push('')
        }
        principal = $1.indexOf(this.selectedHour)
        backwardOffset = principal - 4
        forwardOffset = principal + 5
      }
      // this.testing($1.slice(parseInt(backwardOffset), parseInt(forwardOffset)))
      this.auxHours = $1.slice(parseInt(backwardOffset), parseInt(forwardOffset))
      // return this.hoursList
    },
    setHour (hour, zone) {
      this.hour = hour
      if (this.hour !== this.selectedHour || this.zoneName !== this.selectedZone) {
        this.$emit('set-hour', { zoneHour: hour, zoneName: zone, fromCard: true })
      }
      // this.$emit('modal', true)
    },
    hourValidator () {
      if (!this.providerZone.includes(this.selectedHour)) {
        this.setHour(this.providerZone[0], this.selectedZone)
        this.filteredHours()
        // this.selectedHour = this.providerZone[0]
      }
      // this.auxHours = this.filteredHours
    }
  },
  mounted () {
    this.hour = this.selectedHour
    this.filteredHours()
  },
  watch: {
    'selectedHour' (value) {
      this.hour = value
    }
  }
}
</script>

<style scoped>
/*input[type="radio"]{*/
/*  display: none;*/
/*}*/
/*div ~ input[type="radio"]:checked:checked{*/
/*  @apply bg-primary text-white border-none*/
/*}*/
button {
  font-weight: 700;
}
button:disabled {
  border: none;
}
</style>
