<template>
  <div class="my-4 w-full">
    <div class="flex flex-col gap-2 content-center border-b border-gray sm:flex">

      <div class="flex gap-3 mb-4">
        <div v-if="soloLink" class="flex-none w-16 gap-2 text-left">
          <div class="flex flex-col gap-2">
            <label for="companionSelector">Personas</label>
            <select id="companionSelector" class="text-lg border p-1 rounded py-4 px-2 font-bold" v-model="peopleAmount" @change="handleAvailabilities">
              <option v-for="person in 7" :key="person" :active="person[1]">{{ person + 1 }}</option>
            </select>
          </div>
        </div>
        <div v-else class="flex-none gap-2 w-16 text-left">
          <div class="flex flex-col gap-2">
            <label for="companionSelectorSS">Personas</label>
            <select id="companionSelectorSS" class="text-lg border p-1 rounded py-4 px-2 font-bold" v-model="peopleAmount" @change="handleAvailabilities">
              <option v-for="person in collaboration.companions_limit + 1" :key="person" :active="person[collaboration.companions_limit.length + 1]">{{ person }}</option>
            </select>
          </div>
        </div>
        <div class="flex-auto gap-2 text-left">
          <div class="flex flex-col gap-2">
            <label for="dateSelector">Fecha</label>
            <select :disabled="loading" id="dateSelector" @change="dateAvailability" v-model="date" name="dateTest" class="text-lg border p-1 rounded py-4 px-2 font-bold disabled:bg-gray">
              <option selected disabled class="text-gray" value="">Elegir fecha</option>
              <option v-for="date in filteredDates" :key="date" :value="date.value">{{ date?.label?.replaceAll('.', '') }}</option>
            </select>
          </div>
        </div>

        <div class="flex-none gap-2 text-left w-24">
          <div class="flex flex-col gap-2">
            <label for="hourSelector">Hora</label>
            <select :disabled="loading || !date || failProviderZones" id="hourSelector" v-model="selectedHour" class="text-lg border p-1 rounded disabled:bg-gray py-4 px-2 font-bold" @change="checkReserve(false)">
              <option v-for="hour in hoursList" :key="hour">{{ hour }}</option>
            </select>
          </div>
        </div>
      </div>
    </div>

    <a-loader v-if="loading || loadingCheck" class="h-8 w-8 block mx-auto mt-4" :color="'text-gray'"/>
    <div v-else-if="!haveAvailableDates" class="text-left mt-4">
      <p class="text-red">No hay reservas disponibles.</p>
    </div>
    <div v-else>
      <div v-if="providerZones && date && peopleAmount" class="mt-6">
        <p v-if="!failProviderZones" class="text-left">Elige un horario a continuación:</p>
        <div v-for="(hourList, key, index) in providerZones" :key="key" class="flex flex-col text-left mb-4" :id="`zoneName-${index}`">
          <zone-card
            :provider-zone="hourList"
            :hours-list="hoursList"
            :zone-name="key"
            :index="index"
            @set-hour="setHour"
            :selected-zone="zone"
            :selected-hour="selectedHour"
            :loading="loading" />
        </div>
      </div>
      <div v-else-if="date && !providerZones && !loading && selectedHour" class="text-left">
        <p class="text-red mt-4">No hay zonas disponibles para la realizar la reserva.</p>
      </div>
      <div v-if="!providerZones" class="text-left mt-4">
        <p class="text-red">No hay mesas disponibles para el {{ date?.label?.replaceAll('.', '') }}</p>
<!--        <p v-if="bookingFailError" class="text-red">No hay reservas disponibles.</p>>-->
<!--        <p v-else-if="!matches.availability" class="text-red">No hay disponibilidad para la realizar la reserva.3 </p>-->
<!--        <p v-if="!matches.availability && !matches.people && !matches.time && !matches.zone" class="text-red mt-4">No hay zonas disponibles para la realizar la reserva</p>-->
<!--        <div v-else>-->
<!--          <p v-if="!matches.peoples" class="text-red">No hay disponibilidad para la cantidad de personas seleccionadas</p>-->
<!--          <p v-if="!matches.time" class="text-red">No hay disponibilidad para la hora seleccionada</p>-->
<!--          <p v-if="!matches.zone" class="text-red">La zona seleccionada no está disponible</p>-->
<!--        </div>-->
      </div>
      <div v-if="failProviderZones" class="text-left">
        <p class="text-red mt-4">No hay zonas disponibles para la realizar la reserva.</p>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref, onMounted } from 'vue'
import { useRepository } from '@/hooks'
import Moment from 'moment'
import ZoneCard from './zone-card'
// import Moment from 'moment'
export default {
  name: 'booking',
  components: { ZoneCard },
  props: {
    collaboration: { required: false, type: Object },
    restaurant: { required: false, type: Object },
    availableDates: { required: false, type: Object },
    // providerZones: { required: false, type: Object },
    soloLink: { required: false, type: Boolean, default: false }
  },
  setup (props, { emit }) {
    const booking = useRepository(({ bookings }) => bookings)
    // const $filters = inject('$filters', {})
    const date = ref('empty')
    const peopleAmount = ref(1)
    const zone = ref('')
    const selectedHour = ref(null)
    const bookingHours = ref([])
    const providerZones = ref(null)
    const loading = ref(false)
    const loadingCheck = ref(false)
    const bookingFail = ref(false)
    const bookingFailError = ref(false)
    const matches = ref([])
    const failProviderZones = ref(false)
    const confirmModal = ref(false)
    const availableDatesList = ref([])
    const validDate = ref(false)
    const haveAvailableDates = ref(true)
    // const dateIndex = ref(null)

    const filteredDates = computed(() => {
      let days
      if (props.soloLink) {
        days = availableDatesList.value
      } else {
        days = availableDatesList.value.filter((date) => {
          // const dateMoment = Moment(date)
          const startAt = Moment.utc(props.collaboration.start_at).format('YYYY-MM-DD')
          const endAt = Moment.utc(props.collaboration.end_at).format('YYYY-MM-DD')
          // const allDays = props.collaboration?.allowed_week_days?.length > 6
          const isAfter = Moment(date).isAfter(startAt)
          const isBefore = Moment(date).isBefore(endAt)
          const isSameStart = Moment(date).isSame(startAt)
          const isSameEnd = Moment(date).isSame(endAt)
          const weekDay = Moment(date).isoWeekday()
          const isWeekDay = props.collaboration?.allowed_week_days.includes(weekDay)
          if ((isAfter && isBefore) || isSameStart || isSameEnd) {
            if (isWeekDay) {
              return date
            }
          }
        })
      }
      return days.map((date, index) => {
        return {
          label: Moment(date).format('ddd, DD MMM'),
          value: date,
          index: parseInt(index)
        }
      })
    })

    const filterAvailableDates = computed(() => {
      return props.availableDates.map((date, index) => {
        return {
          label: Moment(date).format('ddd, DD MMM'),
          value: date,
          index: parseInt(index)
        }
      })
    })

    const checkReserve = (fromCard) => {
      const body = {
        restaurant_id: props.restaurant.id,
        date: date.value + ' ' + selectedHour.value,
        people_amount: !props.soloLink ? parseInt(peopleAmount.value) : parseInt(peopleAmount.value),
        cm_zone: zone.value
      }
      bookingFail.value = false
      loadingCheck.value = !fromCard
      emit('loading', true)
      emit('booking-modal', false)
      booking.verifyAvailability(body)
        .then(({ data }) => {
          bookingHours.value = data.available_hours
          // bookingFail.value = !data.available
          // checkBookingFail.value = false
          // if (!fromCard) {
          //   loadingCheck.value = false
          // }
          emit('loading', false)
          if (!data.available || !data.match_people || !data.match_time) {
            // bookingFail.value = true
            matches.value = {
              availability: data.available,
              peoples: data.match_people,
              time: data.match_time
            }
            emit('invalid-date', true)
          } else {
            bookingFail.value = false
            const form = {
              date: date.value + ' ' + selectedHour.value,
              people_amount: peopleAmount.value,
              time: selectedHour.value,
              zone: zone.value
            }
            emit('invalid-date', false)
            emit('validated-date', form)
            if (fromCard) {
              emit('booking-modal', true)
            }
          }
        })
        .finally(() => (loadingCheck.value = false))
        .catch(({ response }) => {
          loadingCheck.value = false
          bookingFail.value = true
          bookingFailError.value = true
          emit('loading', false)
          emit('invalid-date', true)
        })
    }
    const getAvailableDates = () => {
      loading.value = true
      selectedHour.value = null
      date.value = ''
      emit('validated-date', { form: { date: ' ', zone: '' } })
      booking.availableDates({ restaurant_id: props?.restaurant?.id, people_count: peopleAmount.value ? parseInt(peopleAmount.value) : parseInt(props?.collaboration?.companions_limit + 1) })
        .then(({ data: response }) => {
          availableDatesList.value = response?.available_dates
          validDate.value = !!availableDatesList.value.length
          date.value = response.available_dates[0]
          if (response?.available_dates?.length > 0) {
            if (validDate.value && filteredDates.value.length > 0) {
              date.value = filteredDates.value[0].value
              // console.log(filteredDates.value[0].value)
              haveAvailableDates.value = true
              dateAvailability()
              emit('valid-dates', true)
            } else {
              haveAvailableDates.value = false
              emit('valid-dates', false)
            }
            // providerZones.value = response?.available_hours_per_zone
          } else {
            haveAvailableDates.value = false
          }
        })
        .catch(({ response }) => {
          emit('error-code', response?.data?.error_code)
          loading.value = false
          haveAvailableDates.value = false
        })
        // .finally(() => (loading.value = false))
    }

    const dateAvailability = () => {
      loading.value = true
      selectedHour.value = null
      zone.value = false
      emit('loading', true)

      const body = {
        restaurant_id: props.restaurant.id,
        date: date.value,
        people_count: parseInt(peopleAmount.value)
      }
      bookingFail.value = false
      booking.dateAvailability(body)
        .then(({ data: response }) => {
          providerZones.value = response?.available_hours_per_zone
          // hoursList.value = Object.values(providerZones.value)[0]
          const haveEmptyZones = ('default' in response.available_hours_per_zone) && providerZones.value.default.length === 0
          const failedDateIndex = filteredDates.value.flatMap((value) => {
            if (value.value === date.value) {
              return value.index
            } else {
              return []
            }
          })
          if (haveEmptyZones) {
            const index = parseInt(failedDateIndex) + 1

            if (index > filteredDates.value.length) {
              bookingFail.value = true
              failProviderZones.value = true
              emit('loading', false)
              emit('invalid-date', true)
              loading.value = false
            } else {
              date.value = filteredDates.value[index].value
              dateAvailability()
            }
          } else {
            setHour({
              zoneHour: props.collaboration?.booking?.time ? props.collaboration?.booking?.time : providerZones.value[Object.keys(providerZones.value)[0]][0],
              zoneName: Object.keys(providerZones.value)[0]
            })
            loading.value = false
            bookingFail.value = false
            failProviderZones.value = false
            emit('loading', false)
            emit('invalid-date', false)
            emit('validated-date', true)
          }
        })
        .catch(() => {
          emit('loading', false)
          emit('invalid-date', true)
          loading.value = false
          bookingFail.value = true
        })
      // .finally(() => (loadingBooking.value = false))
    }
    const setHour = ({
      zoneHour,
      zoneName,
      fromCard
    }) => {
      selectedHour.value = zoneHour
      zone.value = zoneName
      if (zoneHour !== ' ' && name !== ' ') {
        checkReserve(fromCard)
      }
    }
    const hoursList = computed(() => {
      const hours = []
      if (getHourSteps.value) {
        for (let x = 0; x < 24; x++) {
          const $0 = x < 10 ? '0' + x + ':' + '00' : x + ':' + '00'
          const $1 = x < 10 ? '0' + x + ':' + '15' : x + ':' + '15'
          const $2 = x < 10 ? '0' + x + ':' + '30' : x + ':' + '30'
          const $3 = x < 10 ? '0' + x + ':' + '45' : x + ':' + '45'
          hours.push($0.toString())
          hours.push($1.toString())
          hours.push($2.toString())
          hours.push($3.toString())
        }
      } else {
        for (let x = 0; x < 24; x++) {
          const $0 = x < 10 ? '0' + x + ':' + '00' : x + ':' + '00'
          const $1 = x < 10 ? '0' + x + ':' + '30' : x + ':' + '30'
          hours.push($0.toString())
          hours.push($1.toString())
        }
      }

      return hours
    })
    const getHourSteps = computed(() => {
      if (providerZones.value) {
        const regex = new RegExp(/:15*|:45*/)
        const compareZone = providerZones.value[Object.keys(providerZones.value)[0]]
        const even = (element) => regex.test(element)
        return compareZone.some(even)
      }
      return []
    })
    // watch(selectedHour, () => {
    //   console.log(zone.value, Object.keys(providerZones.value)[0])
    //   if (zone.value === ' ') {
    //     setHour({ zoneHour: '20:00', zoneName: Object.keys(providerZones.value)[0] })
    //   }
    // })
    // watch(date, () => {
    //   dateAvailability()
    // })
    const handleAvailabilities = () => {
      if (date.value !== ' ') {
        getAvailableDates()
      } else {
        dateAvailability()
      }
    }
    onMounted(() => {
      peopleAmount.value = !props.soloLink ? props.collaboration.companions_limit + 1 : 2
      getAvailableDates()
      if (props.collaboration?.booking?.status === 'active') {
        date.value = props.collaboration.booking.date
        selectedHour.value = props.collaboration.booking.time
        peopleAmount.value = props.collaboration.booking.people_amount
        dateAvailability()
      }
    })
    return {
      date,
      peopleAmount,
      booking,
      zone,
      selectedHour,
      providerZones,
      bookingHours,
      loading,
      loadingCheck,
      bookingFail,
      filteredDates,
      filterAvailableDates,
      hoursList,
      matches,
      failProviderZones,
      confirmModal,
      haveAvailableDates,
      bookingFailError,
      checkReserve,
      dateAvailability,
      setHour,
      getAvailableDates,
      handleAvailabilities
    }
  }
}
</script>

<style scoped>

</style>
