<template>
  <a-alert
    loader
    :title="'Tu reserva'"
    :show="showModal && !completed"
    :loading="loading || loadingReservation"
    :disabled-confirm="(soloLink && !first_name) || (soloLink && !last_name) || (soloLink && !email) || (soloLink && !validatedEmail) || (soloLink && !phone) || (soloLink && phone.length < 9)"
    :loaderStyle="'h-4 w-4 text-gray'"
    @cancel="$emit('update:showModal', false)"
    @confirm="$emit('confirm-booking', true)"
    class="transition-all"
  >
    <template #title>
      <div class="text-center">
        <p v-if="!covermanagerId" class="text-lg">Información sobre tu reserva</p>
        <p v-else class="text-lg">Tu reserva</p>
        <p class="mt-2 text-dgray">{{ restaurant.name }}</p>
      </div>
    </template>
    <div v-if="loading">
      <a-loader class="h-8 w-8 block mx-auto" :color="'text-gray'" />
    </div>
    <div v-else >
      <div class="font-bold">
        <div class="block text-lg">
          <i class="icon-userGroup inline-block mr-4 text-xl" /><p class="inline-block">Grupo de {{ reservationForm.people_amount }}</p>
        </div>
        <div v-if="reservationForm.zone !== null" class="block text-lg mt-2">
          <i class="icon-table inline-block mr-4 text-xl" /><p class="inline-block">{{ reservationForm.zone !== 'default' ? reservationForm.zone : 'Salón' }}</p>
        </div>
        <div class="block text-lg mt-2">
          <i class="icon-calendar-01 inline-block mr-4 text-xl align-top" />
          <div class="inline-block">
            <p class="capitalize">{{ formattedDate }}</p>
            <p class="text-gray-400">{{ formattedTime }}</p>
          </div>
        </div>
      </div>
      <div v-if="soloLink" class="mt-4">
        <div class="flex gap-x-2">
          <div class="flex-1">
            <label for="name">Nombre</label>
            <input
              id="name"
              :value="first_name"
              @input="$emit('update:first_name', $event.target.value)"
              type="text"/>
          </div>
          <div class="flex-1">
            <label for="last_name">Apellido</label>
            <input
              id="last_name"
              :value="last_name"
              @input="$emit('update:last_name', $event.target.value)"
              type="text"/>
          </div>
        </div>
        <div class="mt-2">
          <label for="email" class="mt-2">Email</label>
          <input
            id="email"
            :value="email"
            @input="$emit('update:email', $event.target.value)"
            type="email"/>
        </div>

        <div class="mt-2">
          <label for="phone" class="mt-2">Teléfono</label>
          <div class="text-left flex items-center py-2 px-2 border rounded-md border-dgray" id="phone-container">
            <p class="justify-self-end inline-block text-gray text-lg mt-1 mr-1">+34</p>
            <!--      <input type="text" :value="`${user.phone}`"  class="align-middle border-none underlined py-2 inline-block" style="background: white;width: calc(100% - 120px);" id="phone">-->
            <Mask
              id="phone"
              v-model="newPhone"
              class="inline-block text-lg"
              style="width: 100% !important;"
              placeholder="Teléfono para reserva *"
              @keypress.="phoneFormatter($event)"
              @focus="phoneAux = ''"
              mask="999999999"/>
          </div>
<!--          {{ phone.length < 9 ? 'menor' : 'mayor' }}-->
        </div>

      </div>
      <div v-if="covermanagerId" class="mt-4">
        <span class="block text-sm mb-2 md:text-lg">¿Agregar una solicitud especial (opcional)?</span>
        <textarea
          v-model="newComment"
          :disabled="isEditingView"
          @input="$emit('update-comment', $event.target.value)"
          rows="4"
          placeholder="Introduce cualquier petición adicional (ten en cuenta que no están garantizadas)"
          class="border rounded-xl w-full p-2 focus:outline-none focus:ring-2 focus:ring-primary focus:ring-opacity-50 disabled:bg-gray">
      </textarea>
      </div>
    </div>
  </a-alert>
</template>

<script>
import Moment from 'moment'
import Mask from 'primevue/inputmask'
import { getCurrentInstance } from 'vue'

export default {
  name: 'confirm-modal',
  components: { Mask },
  props: {
    showModal: { required: false, type: Boolean },
    collaboration: { required: false, type: Object },
    restaurant: { required: false, type: Object },
    loadingReservation: { required: false, type: Boolean, default: false },
    reservationForm: { required: false, type: Object },
    loading: { required: false, type: Boolean, default: false },
    completed: { required: false, type: Boolean, default: false },
    soloLink: { required: false, type: Boolean, default: false },
    first_name: { required: false, type: String },
    last_name: { required: false, type: String },
    email: { required: false, type: String },
    phone: { required: false, type: String }
    // comment: { required: false, type: String },
  },
  emits: ['update:showModal', 'update:first_name', 'update:last_name', 'update:email', 'update:phone', 'update-comment', 'confirm-booking'],
  data () {
    return {
      newComment: '',
      newPhone: '',
      isEditingView: false,
      phoneAux: '',
      validatedEmail: false,
      covermanagerId: null
    }
  },
  computed: {
    formattedDate () {
      return Moment(this.reservationForm.date).format('dddd, DD MMMM')
    },
    formattedTime () {
      return Moment(this.reservationForm.date).format('HH:mm')
    }
  },
  methods: {
    test (event) {
      console.log('from test: ', event)
    },
    phoneFormatter (phone) {
      this.phoneAux = phone.replace(/\D/g, '')
      this.$emit('update:phone', this.phoneAux)
    }
  },
  watch: {
    newPhone (value) {
      const aux = value.replace(/\D/g, '')
      this.$emit('update:phone', aux)
    },
    email (value) {
      if (value.match('^[a-z0-9!#$%&\'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&\'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$')) {
        this.validatedEmail = true
      } else {
        this.validatedEmail = false
      }
    }
  },
  mounted () {
    if (!this.soloLink) {
      this.isEditingView = getCurrentInstance().parent.parent.type.name === 'reserved-action'
      this.covermanagerId = !!this.restaurant.covermanager_id
      this.newComment = this.collaboration?.booking?.comment ? this.collaboration?.booking?.comment : ''
    }
  }
}
</script>

<style scoped>
/*#phone{*/
/*  height: 48px !important;*/
/*  border: none;*/
/*  padding: 0 10px;*/
/*  line-height: 48px;*/
/*  display: inline-block;*/
/*  vertical-align: middle;*/
/*  text-align: left !important;*/
/*  font-size: 14px !important;*/
/*}*/
/*#phone:enabled:focus {*/
/*  !*border: none !important;*!*/
/*  box-shadow: 0 0 0 0 #23BB2D !important;*/
/*  border: none;*/
/*  border-radius: 0;*/
/*}*/
/*.p-inputtext{*/
/*  background-color: #fff !important;*/
/*}*/
.p-inputmask{
  background-color: white !important;
  border: none;
  text-align: left !important;
  font-size: 16px !important;
  padding: 0 !important;
  width: auto;
}
.p-inputmask:enabled:focus {
  /*border: none !important;*/
  box-shadow: 0 0 0 0 #23BB2D !important;
  border: none;
  border-radius: 0;
}
.p-inputtext{
  border-radius: 0 !important;
}
#phone-container{
  border-color: #d1d1d1 !important;
}
</style>
